import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"
import MetroFaresPrimary from "../components/metro-fares-primary"

class G3ServicePage extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="G3"
          description="View route information and buy your ticket for the G3 service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">G3 service</h1>
              <p>Swillington Church - St Benedict's Garforth</p>
              <p>St Benedict's Garforth - Swillington Church</p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h2>Route</h2>
                <p style={{ margin: '15px 0'}}>This service will pick up/set down at all regular bus stops along the following route:</p>
                <h3 style={{ margin: '10px 0'}}>AM route</h3>
                <iframe
                  title="G3 AM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1K9XwYKuYWjiv-lwU0wdo0sXn5mK1TeM&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>Towards</strong> St Benedict's School
                </p>
                <p>
                From Swillington Church all stops via Wakefield Road, Goody Cross Lane, Whitehouse Lane, Preston Lane, Leeds Road, to turn at Vicars Terrace, Park Lane, then return to Great Preston Corner via Leeds Road, Preston Lane, then Berry Lane, Station Road, Butt Hill, Cross Hills, High Street, Gibson Lane, Leeds Road, Valley Road, Leeds Road, Selby Road, Ninelands Lane, Church Lane, Oak Crescent, Station Fields, Oak Drive.
                </p>

                <h3 style={{ margin: '10px 0'}}>PM route</h3>
                <iframe
                  title="G3 PM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1t1jWZITtac7mAWeNZi2zekBTRTjY590&ehbc=2E312F"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>From</strong> St Benedict's School
                </p>
                <p>
                From Oak Drive, Station Fields, Oak Crescent, Church Lane, Ninelands Lane, Selby Road, Leeds Road, Gibson Lane, High Street, Cross Hills, Butt Hill, Station Road, Berry Lane, Preston Lane, Leeds Road, to turn at Vicars Terrace, Park Lane, then return toward Goody Cross and Swillington via Leeds Road, Preston Lane, Whitehouse Lane, Goody Cross Lane, Wakefield Road to Swillington Church.
                </p>
              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Morning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Swillington Church</td>
                      <td>DEP</td>
                      <td>0820</td>
                    </tr>
                    <tr>
                      <td>Brigshaw Lane End</td>
                      <td>DEP</td>
                      <td>0825</td>
                    </tr>
                    <tr>
                      <td>Kippax Cross Hills</td>
                      <td>DEP</td>
                      <td>0830</td>
                    </tr>
                    <tr>
                      <td>Kippax Valley Road</td>
                      <td>DEP</td>
                      <td>0835</td>
                    </tr>
                    <tr>
                      <td>Ninelands Lane</td>
                      <td>DEP</td>
                      <td>0840</td>
                    </tr>
                    <tr>
                      <td>St Benedicts School</td>
                      <td>ARR</td>
                      <td>0850</td>
                    </tr>
                  </tbody>
                </table>

                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Afternoon</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>St Benedicts School</td>
                      <td>DEP</td>
                      <td>1525</td>
                    </tr>
                    <tr>
                      <td>Ninelands Lane</td>
                      <td>DEP</td>
                      <td>1539</td>
                    </tr>
                    <tr>
                      <td>Kippax Valley Road</td>
                      <td>DEP</td>
                      <td>1542</td>
                    </tr>
                    <tr>
                      <td>Kippax Cross Hills</td>
                      <td>DEP</td>
                      <td>1545</td>
                    </tr>
                    <tr>
                      <td>Brigshaw Lane End</td>
                      <td>DEP</td>
                      <td>1550</td>
                    </tr>
                    <tr>
                      <td>Swillington Church</td>
                      <td>ARR</td>
                      <td>1600</td>
                    </tr>
                  </tbody>
                </table>

                <MetroFaresPrimary />
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default G3ServicePage
